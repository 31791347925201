<template>
  <div class="">
    <el-tabs v-model="activeName" type="card" @tab-click="changeTab">
      <el-tab-pane name="1" label="人员管理">
        <div class="member-manage">
          <el-form :inline="true" class="form-content">
            <el-form-item>
              <el-input
                placeholder="请输入关键字查询"
                v-model="labelName"
                class="input-with-select"
                style="width: 450px"
              >
                <el-select
                  v-model="select"
                  slot="prepend"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <el-option label="姓名" :value="1"></el-option>
                  <el-option label="手机号" :value="2"></el-option>
                </el-select>
                <el-button
                  slot="append"
                  @click="getList"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="机构">
              <el-select
                placeholder="请选择"
                v-model="organId"
                clearable
                style="width: 240px"
                filterable=""
              >
                <el-option
                  v-for="(item, index) in organManageList"
                  :key="index"
                  :value="item.id"
                  :label="item.organName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="团队角色">
              <el-select
                placeholder="请选择"
                v-model="roleIds"
                clearable
                style="width: 240px"
                filterable=""
              >
                <el-option
                  v-for="(item, index) in roleList"
                  :key="index"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核状态">
              <el-select
                placeholder="请选择"
                v-model="status"
                clearable
                style="width: 240px"
                filterable=""
              >
                <el-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :value="item.value"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">搜索</el-button>
              <el-button @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="search-list">
          <el-button type="primary" @click="handleAdd">添加</el-button>
	
		  	  <el-button type='primary' @click='resetPassword' >重置密码</el-button>
		 
          <el-button
            type="danger"
            @click="deleteHandle"
            :disabled="dataListSelections.length ? false : true"
            >移除</el-button
          >
        </div>
        <div class="table">
          <el-table
            :data="dataList"
            style="width: 100%"
            v-loading="dataListLoading"
            @selection-change="selectionChangeHandle"
          >
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column
              prop="docterName"
              label="姓名"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="phone"
              label="手机号"
              width="150"
            ></el-table-column>
            <el-table-column prop="organName" label="所属机构">
              <template slot-scope="scope">{{
                scope.row.organName ? scope.row.organName : "-"
              }}</template>
            </el-table-column>
            <el-table-column prop="roleName" label="团队角色" width="150"
              ><template slot-scope="scope">{{
                scope.row.roleName ? scope.row.roleName : "-"
              }}</template></el-table-column
            >
            <el-table-column
              prop="createTime"
              label="加入时间"
              width="200"
            ></el-table-column>
            <el-table-column prop="createName" label="认证状态" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.auditStatus == null"
                  ><span class="dot dot-2"></span>未认证</span
                >
                <span v-else-if="scope.row.auditStatus == 1"
                  ><span class="dot dot-1"></span>已认证</span
                >
                <span v-else-if="scope.row.auditStatus == 2">待审核</span>
                <span v-else>未通过</span>
              </template></el-table-column
            >
            <el-table-column prop="createName" label="审核状态" width="120"
              ><template slot-scope="scope">
                <span v-if="scope.row.status == 1"
                  ><span class="dot dot-1"></span>已通过</span
                >
                <span v-else-if="scope.row.status == 3"
                  ><span class="dot dot-3"></span>待审核</span
                >
                <span v-else-if="scope.row.status == 4"
                  ><span class="dot dot-2"></span>未通过</span
                >
              </template></el-table-column
            >
            <el-table-column
              label="操作"
              header-align="right"
              align="right"
              width="120"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleEdit(scope.row)"
                  v-if="scope.row.status == 1"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleEdit(scope.row, 2)"
                  v-if="scope.row.status == 3"
                  >通过</el-button
                >
                <el-button
                  type="text"
                  @click="showRefuseDialog(scope.row)"
                  v-if="scope.row.status == 3"
                  >拒绝</el-button
                >
                <el-button type="text" v-if="scope.row.status == 4"
                  >-</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane name="2" label="机构管理">
        <div class="search-list">
          <el-button type="primary" @click="handleAdd">添加</el-button>
          <el-button
            type="danger"
            @click="deleteHandle"
            :disabled="dataListSelections.length ? false : true"
            >删除</el-button
          >
        </div>
        <div class="table">
          <el-table
            :data="dataList2"
            style="width: 100%"
            v-loading="dataListLoading"
            @selection-change="selectionChangeHandle"
          >
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column
              prop="organNum"
              label="机构号"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="organName"
              label="机构名称"
            ></el-table-column>
            <el-table-column
              prop="roleName"
              label="角色"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="createName"
              label="操作人"
              width="200"
            ></el-table-column>
            <el-table-column
              label="操作"
              header-align="right"
              align="right"
              width="100"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-pagination
        v-if="activeName == 1"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-tabs>

    <el-dialog
      :title="title"
      :visible.sync="addMemberDialog"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addForm"
        label-width="100px"
        :rules="addDataRules"
        ref="addForm"
        class="dialog-form-con"
      >
        <el-form-item label="姓名" :prop="isEdit ? 'docterName' : ''">
          <el-input
            v-if="isEdit"
            v-model="addForm.docterName"
            placeholder="请输入"
            style="width: 350px"
          ></el-input>
          <div v-else style="width: 350px">{{ addForm.docterName }}</div>
        </el-form-item>
        <el-form-item label="手机号" :prop="isEdit ? 'phone' : ''">
          <el-input
            v-model="addForm.phone"
            placeholder="请输入"
            style="width: 350px"
            maxlength="11"
            v-if="isEdit"
          ></el-input>
          <div v-else style="width: 350px">{{ addForm.phone }}</div>
        </el-form-item>
        <el-form-item label="角色" prop="roleIds">
          <el-select
            placeholder="请选择"
            v-model="addForm.roleIds"
            clearable
            style="width: 350px"
          >
            <el-option
              v-for="(item, index) in roleList"
              :key="index"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构" prop="">
          <el-select
            placeholder="请选择"
            v-model="addForm.organId"
            clearable
            style="width: 350px"
          >
            <el-option
              v-for="(item, index) in organManageList"
              :key="index"
              :value="item.id"
              :label="item.organName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据权限" prop="dataType">
          <el-select
            placeholder="请选择"
            v-model="addForm.dataType"
            clearable
            style="width: 350px"
            v-if="addForm.organId"
          >
            <el-option
              v-for="(item, index) in dataPermissionList"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-select
            placeholder="请选择"
            v-model="addForm.dataType"
            clearable
            style="width: 350px"
            v-else
          >
            <el-option
              v-for="(item, index) in dataPermissionList2"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="指定机构"
          prop="dataTypes"
          v-if="addForm.dataType == 3"
        >
          <el-select
            placeholder="请选择（可多选）"
            v-model="addForm.dataTypes"
            clearable
            style="width: 350px"
            multiple=""
            filterable=""
          >
            <el-option
              v-for="(item, index) in organManageList"
              :key="index"
              :value="item.id"
              :label="item.organName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="addMemberDialog = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="审核拒绝" :visible.sync="refuseDialog" width="800px">
      <el-form
        :rules="refuseDataRules"
        :model="refuseData"
        inline
        ref="refuseContent"
      >
        <el-form-item label="拒绝原因" prop="content">
          <el-input
            type="textarea"
            rows="8"
            v-model="refuseData.content"
            style="width: 660px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="refuseContent">确 定</el-button>
        <el-button @click="refuseDialog = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 添加机构 -->
    <el-dialog
      :title="addForm2.id ? '编辑机构' : '添加机构'"
      :visible.sync="addOrgDialog"
      width="800px"
    >
      <el-form
        :model="addForm2"
        label-width="100px"
        :rules="addDataRules2"
        ref="addForm2"
        class="dialog-form-con"
      >
        <el-form-item label="机构号" prop="organNum">
          <el-input
            v-model="addForm2.organNum"
            placeholder="请输入"
            style="width: 350px"
          ></el-input>
        </el-form-item>
        <el-form-item label="机构名称" prop="organId">
          <el-select
            placeholder="请选择（可搜索）"
            v-model="addForm2.organId"
            clearable
            style="width: 350px"
            filterable=""
          >
            <el-option
              v-for="(item, index) in hospitalList"
              :key="index"
              :value="item.id"
              :label="item.fullName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构角色" prop="roleId">
          <el-select
            placeholder="请选择"
            v-model="addForm2.roleId"
            clearable
            style="width: 350px"
          >
            <el-option
              v-for="(item, index) in roleList"
              :key="index"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitOrg">确 定</el-button>
        <el-button @click="addOrgDialog = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Qs from "qs";
var regPhone =
  /^(13[0-9]|14[14-8]|15[0-35-9]|16[6]|17[0-8]|18[0-9]|19[0-9])[0-9]{8}$/;
export default {
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else if (!regPhone.test(value)) {
        callback(new Error("联系方式格式不正确"));
      } else {
        callback();
      }
    };
    return {
      activeName: "1",
      select: 1,
      labelName: "",
      organManageList: [], //机构列表
      organId: "",
      roleList: [
        // { name: "团队管理员", value: 1 },
        // { name: "数据管理员", value: 2 },
        // { name: "机构管理员", value: 3 },
        // { name: "录入员", value: 4 },
        // { name: "普通用户", value: 5 },
        // { name: "其他", value: 6 },
      ], //角色列表
      roleIds: "",
      statusList: [
        { name: "待审核", value: 3 },
        {
          name: "已通过",
          value: 1,
        },
        { name: "未通过", value: 4 },
      ],
      status: "",
      dataList: [],
	  dataList2: [],
      dataListLoading: false, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      addMemberDialog: false, //添加成员弹窗显示
      addForm: {
        docterName: "", //姓名
        phone: "", //手机号
        roleIds: "",
        organId: "",
        dataType: "", //数据权限
        dataTypes: [], //指定机构
      },
      addDataRules: {
        docterName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        roleIds: [{ required: true, message: "请选择角色", trigger: "change" }],
        dataType: [
          { required: true, message: "请选择数据权限", trigger: "change" },
        ],
        dataTypes: [
          { required: true, message: "请选择指定机构", trigger: "change" },
        ],
      },
      isEdit: true, //是否为编辑状态
      title: "添加成员",
      dataPermissionList: [
        { name: "全部", value: "4" },
        { name: "本机构", value: "1" },
        { name: "仅自己创建", value: "2" },
        { name: "指定机构", value: "3" },
      ],
      dataPermissionList2: [
        { name: "全部", value: "4" },
        { name: "仅自己创建", value: "2" },
        { name: "指定机构", value: "3" },
      ],
      refuseDialog: false, //审核拒绝弹窗
      refuseDataRules: {
        content: [
          { required: true, message: "请输入拒绝原因", trigger: "blur" },
        ],
      },
      refuseData: {},

      //机构管理
      organManageList: [], //机构列表
      addOrgDialog: false, //机构弹窗
      addForm2: {
        organNum: "",
        organId: "",
        roleId: "",
      },
      addDataRules2: {
        organNum: [
          { required: true, message: "请输入机构号", trigger: "blur" },
        ],
        organId: [
          { required: true, message: "请选择机构名称", trigger: "change" },
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
      teamsId: "",
      enertyId: "",
      hospitalId: "",
      hospitalList: [], //机构名称列表
      organRoleList: [], //机构角色
      flag: true, //防止提交重复点击
    };
  },
  async mounted() {
    if (localStorage.currentTeam) {
      this.teamsId = JSON.parse(localStorage.currentTeam).id;
      this.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      this.getList();
      this.getRoleList(2);
      this.getOrgList();
    }
  },
  methods: {
	  async resetPassword(){
	    if(this.dataListSelections.length>1){
	      return this.$message.error('一次只能重置一个账号密码')
	    }
	    const id =(this.dataListSelections.map(v=>v.docterId)).join(',')
	    // console.log(id,'id');
	    const {data:res} = await this.$httpAes({
	      url:this.$httpAes.adornUrl('/organBusiness/resetpswd'),
	      method:'POST',
	      data:{stringParam1:id}
	    })
	    console.log(res);
	    if(res.status){
	      this.$message.success('重置密码成功')
	    }else{
	      this.$message.error(res.msg+'重置密码失败，请重试')
	    }
	  },
    // 获取机构管理
    getOrgList() {
      let obj = {
        pageNo: 1,
        pageSize: 999,
        teamsId: this.teamsId,
      };
      this.$http({
        url: this.$http.adornUrl("/mbglOrganManage/list"),
        method: "get",
        params: obj,
      }).then(({ data }) => {
        this.organManageList = data.data;
      });
    },
    getRoleList(roleType) {
		
      let deptId = this.$cookie.get("hospitalId");
      let obj = {
        intParam2: 1,
        intParam1: 999,
        intParam3: roleType,
      };
      this.$httpAes({
        url: this.$httpAes.adornUrl("/role/page"),
        method: "post",
         data:obj
      }).then(({ data }) => {
        this.roleList = data.data;
      });
    },
    async changeTab() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.totalPage = 0;
      this.dataListSelections = [];
      await this.getList();
    },
    async getList() {
      let params = {
        stringParam5: this.teamsId,
      };
      if (this.activeName == 1) {
        params.intParam1 = this.pageSize;
        params.intParam2 = this.pageNo;
        if (this.select == 1) {
          params.stringParam3 = this.labelName;
        } else {
          params.stringParam4 = this.labelName;
        }
        params.stringParam2 = this.organId;
        params.stringParam1 = this.roleIds;
        params.stringParam6 = this.status;
      } else {
        params.intParam1 = 100;
        params.intParam2 = 1;
      }
if(  this.activeName == 1){
	const { data: res } = await this.$httpAes({
	  url: this.$httpAes.adornUrl(
	   "/teamsMember/list" 
	  ),
	  method: "post",
	  data: params,
	});
	if (res.status) {
	  this.dataList = res.data;
	  this.totalPage = res.totalCount;
	}
}else{
	let obj = {
	  pageNo: 1,
	  pageSize: 999,
	  teamsId: this.teamsId,
	};
	this.$http({
	  url: this.$http.adornUrl("/mbglOrganManage/list"),
	  method: "get",
	  params: obj,
	}).then(({ data }) => {
	 this.dataList2 = data.data;
	 this.totalPage = data.totalCount;
	});
}
     
      this.dataListLoading = false;
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除标签]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(
              this.activeName == 1
                ? "/teamsMember/delete"
                : "/mbglOrganManage/delete"
            ),
            method: "post",
            data: { stringParam1: id },
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getList();
              },
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              duration: 1500,
              onClose: () => {},
            });
          }
        })
        .catch(() => {});
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: "标签类型",
		},
        params: {
          type: "标签类型",
        },
      });
      // console.log(res,'res')
      this.typeList = res.data;
    },
    reset() {
      this.labelName = "";
      this.select = 1;
      this.organId = "";
      this.roleIds = "";
      this.status = [];
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      if (this.activeName == 1) {
        this.getList();
      } else {
        this.getOrgList();
      }
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      if (this.activeName == 1) {
        this.getList();
      } else {
        this.getOrgList();
      }
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    handleAdd() {
      if (this.activeName == 1) {
        this.addMemberDialog = true;
        this.isEdit = true;
        this.title = "添加成员";
        this.$nextTick(() => {
          this.$refs.addForm.clearValidate();
          this.$refs.addForm.resetFields();
          let obj = {
            // id: "",
            docterName: "", //姓名
            phone: "", //手机号
            roleIds: "",
            organId: "",
            dataType: "", //数据权限
            dataTypes: [], //指定机构
          };
          this.addForm = JSON.parse(JSON.stringify(obj));
          this.getRoleList(2);
          this.getOrgList();
        });
      } else {
        this.addOrgDialog = true;
        this.$nextTick(() => {
          this.$refs.addForm2.clearValidate();
          this.$refs.addForm2.resetFields();
          this.addForm2 = {
            // id: "",
            organNum: "",
            organId: "",
            organName: "",
            roleId: "",
          };
        });
        this.getHospitalList();
        this.getRoleList(3);
      }
    },
    handleEdit(row, type) {
      console.log(row);
      if (this.activeName == 1) {
        this.addMemberDialog = true;
        this.isEdit = false;
        if (type == 2) {
          this.title = "审核通过";
        } else {
          this.title = "编辑成员";
        }
        this.$nextTick(() => {
          this.$refs.addForm.clearValidate();
          this.$refs.addForm.resetFields();
          this.addForm = JSON.parse(JSON.stringify(row));
          console.log(this.addForm);
          if (this.addForm.dataTypes) {
            this.addForm.dataTypes = this.addForm.dataTypes.split(",");
          }
          if (this.addForm.dataType == "") {
            this.addForm.dataType = "4";
          }
          this.getRoleList(2);
          this.getOrgList();
        });
      } else {
        this.addOrgDialog = true;
        this.$nextTick(() => {
          this.$refs.addForm.clearValidate();
          this.$refs.addForm.resetFields();
        });
        this.getHospitalList();
        this.getRoleList(3);
        this.addForm2 = JSON.parse(JSON.stringify(row));
      }
    },
    // 提交成员管理信息
    async submit(type) {
      var that = this;
      let params = JSON.parse(JSON.stringify(this.addForm));
      // if (this.addForm.status) {
      //   params.status = this.addForm.status;
      // } else {
      //   params.status = 1;
      // }
      params.teamsId = this.teamsId;

      if (params.dataTypes && params.dataTypes.length) {
        params.dataTypes = this.addForm.dataTypes.join(",");
      } else {
        params.dataTypes = "";
      }
      if (params.organId) {
        this.organManageList.forEach((ele) => {
          if (params.organId == ele.id) {
            params.organName = ele.organName;
          }
        });
      }

      let arr = [];
      this.$refs.addForm.validate(async (valid) => {
        if (params.dataType == 4) {
          params.dataType = "";
        }
        if (this.title == "审核通过") {
          params.status = 1;
        }
        arr.push(params);
        console.log(arr);
        if (this.flag) {
          this.flag = false;
          if (valid) {
            const { data: res } = await this.$http({
              url: this.$http.adornUrl(
                this.addForm.id ? "/teamsMember/update" : "/teamsMember/add"
              ),
              method: "post",
              headers: {
                "Content-Type": this.addForm.id
                  ? "application/x-www-form-urlencoded"
                  : "application/json",
              },
              data: this.addForm.id
                ? Qs.stringify(params)
                : JSON.stringify(arr),
            });
            if (res.status) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  let chatObj = {};
                  if (!this.addForm.id) {
                    chatObj = {
                      userId:
                        res.data[0].docterId.substring(0, 16) +
                        this.teamsId.substring(0, 16),
                      nick: res.data[0].docterName,
                      faceUrl:
                        "https://aibiboat.oss-cn-shanghai.aliyuncs.com/aibiboat-hospital/0/20231013/20231013105507.png?Expires=2012525708&amp;OSSAccessKeyId=LTAIx53xA8wzcyG5&amp;Signature=1wGYYHLpZ0%2FrwmO9ZLaWXHC8F3Y%3D",
                    };
                    that
                      .$http({
                        url: that.$http.adornUrl("/bind/accountImport"),
                        method: "post",
                        // data: {teamsMembers:JSON.stringify(arr)},
                        params: chatObj,
                        headers: {
                          "Content-Type": "application/json",

                          token: that.$cookie.get("token"),
                        },
                      })
                      .then(({ data }) => {
                        if (data && data.status) {
                        } else {
                          that.$message.error(data.msg);
                        }
                      });
                  }

                  this.addMemberDialog = false;
                  this.getList();
                  this.flag = true;
                },
              });
            } else {
              this.$message.error(res.msg);
              this.flag = true;
            }
          } else {
            return false;
          }
        }
      });
    },
    showRefuseDialog(row) {
      this.refuseDialog = true;
      this.addForm = row;
    },
    // 拒绝
    refuseContent() {
      let params = {
        id: this.addForm.id,
        content: this.refuseData.content,
        status: 4,
      };
      this.$refs.refuseContent.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http({
            url: this.$http.adornUrl("/teamsMember/update"),
            method: "post",
            data: Qs.stringify(params),
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.refuseDialog = false;
                this.getList();
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    //获取医院列表
    async getHospitalList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dept/getDepts"),
        method: "post",
		data:{
			stringParam1:''
		}
      });
      // console.log(res,'res')
      this.hospitalList = res.data;
    },
    //提交机构管理
    async submitOrg() {
      this.addForm2.teamsId = this.teamsId;
      this.addForm2.status = 3;
      if (this.addForm2.organId) {
        this.hospitalList.forEach((ele) => {
          if (this.addForm2.organId == ele.id) {
            this.addForm2.organName = ele.fullName;
          }
        });
      }
      if (this.addForm2.roleId) {
        this.roleList.forEach((ele) => {
          if (this.addForm2.roleId == ele.id) {
            this.addForm2.roleName = ele.name;
          }
        });
      }
      // console.log(this.addForm2);
      // return
      if (this.flag) {
        this.$refs.addForm2.validate(async (valid) => {
          if (this.flag) {
            this.flag = false;
            if (valid) {
              const { data: res } = await this.$http({
                url: this.$http.adornUrl(
                  this.addForm2.id
                    ? "/mbglOrganManage/update"
                    : "/mbglOrganManage/add"
                ),
                method: "post",
                data: Qs.stringify(this.addForm2),
              });
              if (res.status) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.addOrgDialog = false;
                    this.getList();
                    this.flag = true;
                  },
                });
              } else {
                this.$message.error(res.msg);
                this.flag = true;
              }
            } else {
              return false;
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-right: 6px;
  vertical-align: middle;
  &.dot-1 {
    background: #10cd30;
  }
  &.dot-2 {
    background: #f41717;
  }
  &.dot-3 {
    background: #ff8800;
  }
}
</style>
